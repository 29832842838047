'use client'

import { useEffect } from 'react'

import * as CookieConsent from 'vanilla-cookieconsent'

import 'vanilla-cookieconsent/dist/cookieconsent.css'

export enum CookieCategory {
  Technical = 'technical',
  Statistical = 'statistical',
  Social = 'social',
  Marketing = 'marketing',
}

const updateCookieConsent = () => CookieConsent.showPreferences()
const resetCookieConsent = () => CookieConsent.reset(true)

export const listenForConsent = () => {
  if (window._ccRun) return

  window.dataLayer = window.dataLayer || []

  function pushEventToGtm(event = 'consent', action = 'update', data?: { [key: string]: string }) {
    window.dataLayer?.push({ event, action, data })
  }

  pushEventToGtm('consent', 'default', {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied',
    functionality_storage: 'denied',
    personalization_storage: 'denied',
    security_storage: 'granted',
  })

  const updateGtagConsent = () => {
    pushEventToGtm('consent', 'update', {
      ad_storage: CookieConsent.acceptedCategory(CookieCategory.Marketing) ? 'granted' : 'denied',
      ad_user_data: CookieConsent.acceptedCategory(CookieCategory.Marketing) ? 'granted' : 'denied',
      ad_personalization: CookieConsent.acceptedCategory(CookieCategory.Marketing) ? 'granted' : 'denied',
      analytics_storage: CookieConsent.acceptedCategory(CookieCategory.Statistical) ? 'granted' : 'denied',
      functionality_storage: CookieConsent.acceptedCategory(CookieCategory.Technical) ? 'granted' : 'denied',
      personalization_storage: CookieConsent.acceptedCategory(CookieCategory.Social) ? 'granted' : 'denied',
      security_storage: 'granted',
    })
  }

  window.addEventListener('cc:onConsent', () => {
    updateGtagConsent()
  })

  window.addEventListener('cc:onChange', () => {
    updateGtagConsent()
  })

  return () => {
    window.removeEventListener('cc:onConsent', updateGtagConsent)
    window.removeEventListener('cc:onChange', updateGtagConsent)
  }
}

const CookieConsentComponent = () => {
  useEffect(() => {
    /**
     * All config. options available here:
     * https://cookieconsent.orestbida.com/reference/configuration-reference.html
     */
    listenForConsent()
    CookieConsent.run({
      disablePageInteraction: true,
      autoShow: true,
      guiOptions: {
        consentModal: {
          layout: 'bar',
          position: 'bottom center',
          equalWeightButtons: false,
          flipButtons: false,
        },
      },
      categories: {
        technical: {
          enabled: true,
          readOnly: true,
        },
        statistical: {},
        social: {},
        marketing: {},
      },
      language: {
        default: 'en',
        autoDetect: 'document',
        translations: {
          en: '/lp/assets/cookies/en.json',
          cs: '/lp/assets/cookies/cs.json',
        },
      },
    })
  }, [])

  return <></>
}

export { CookieConsentComponent, resetCookieConsent, updateCookieConsent }
