import { FestivalStateEnum } from "~/app/generated/graphql"

export const FESTIVAL_SLUG_TO_CODE = {
  ginweek: 'gw',
  restaurantweek: 'rw',
  finediningweek: 'fdw',
  pizzaweek: 'pw',
  breakfastweek: 'bw',
}

export const FESTIVAL_CODE_TO_SLUG = {
  gw: 'ginweek',
  rw: 'restaurant-week',
  fdw: 'finediningweek',
  bw: 'breakfastweek',
  pw: 'pizzaweek',
}

export const SPONSOR = {
  MAIN: 'MAIN',
  PARTNER: 'PARTNER',
  PATRON: 'PATRON',
  REGIONAL: 'REGIONAL',
  REGULAR: 'REGULAR',
  PLEBISCITE_PARTNER: 'PLEBISCITE_PARTNER',
}

interface Image {
  __typename?: string
  id: string
  preview: string
}

export interface Tag {
  __typename?: string
  id: string
  label: string
  kind: string
  subkind: string
  image: Image
  url?: string
}

interface SponsorsResult {
  mainSponsors: Tag[]
  sponsors: { [key: string]: Tag[] }
  partners: Tag[]
  patrons: Tag[]
  regionalPatrons: Tag[]
  plebiscitePartners: Tag[]
}

const TAG_TO_OMIT: string[] = []

export const getSponsors = (logos: Tag[]): SponsorsResult => {
  const tagsCategories = (tagsArr: Tag[], field: keyof Tag): string[] => {
    return Array.from(new Set(tagsArr.map(item => item[field] as unknown as string)))
  }

  const groupTagsByCategory = (tagsArr: Tag[], field: keyof Tag, tagsToOmit: string[] = TAG_TO_OMIT): { [key: string]: Tag[] } => {
    const categoryArr = tagsCategories(tagsArr, field)

    return categoryArr.reduce((tagsMap: { [key: string]: Tag[] }, category) => {
      if (!tagsToOmit.includes(category)) {
        tagsMap[category] = tagsArr.filter(item => (item[field] as unknown as string) === category)
      }

      return tagsMap
    }, {})
  }

  const logosMap = groupTagsByCategory(logos, 'kind')
  const partnersMap = groupTagsByCategory(logosMap[SPONSOR.PARTNER] || [], 'subkind')
  const sponsors = Object.keys(partnersMap).reduce((acc: { [key: string]: Tag[] }, key) => {
    if (key !== SPONSOR.REGULAR) {
      acc[key] = partnersMap[key]
    }
    return acc
  }, {})

  return {
    mainSponsors: logosMap[SPONSOR.MAIN] || [],
    sponsors,
    partners: partnersMap[SPONSOR.REGULAR] || [],
    patrons: logosMap[SPONSOR.PATRON] || [],
    regionalPatrons: logosMap[SPONSOR.REGIONAL] || [],
    plebiscitePartners: logosMap[SPONSOR.PLEBISCITE_PARTNER] || [],
  }
}

export const formatPrice = (price: number | string) => {
  const numPrice = typeof price === 'string' ? parseFloat(price) : price
  const envCurrency = 'PLN' // TODO: detect currency

  return numPrice?.toLocaleString('pl-PL', {
    style: 'currency',
    currency: envCurrency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
}

interface GetRedirectUrlProps {
  festivalEditionState?: FestivalStateEnum, 
  festivalEditionId?: string | number, 
  festivalSlug?: string
}

export const getRedirectUrl = ({
  festivalEditionState, festivalEditionId, festivalSlug
}: GetRedirectUrlProps) => {
  switch(festivalEditionState) {
    case FestivalStateEnum.Voucher:
      return `${process.env.NEXT_PUBLIC_CLUB_URL}/voucher-details/${festivalEditionId}`
      case FestivalStateEnum.Presale:
      return `${process.env.NEXT_PUBLIC_CLUB_URL}/early-reservation/${festivalEditionId}`
      case FestivalStateEnum.Sale:
      return `${process.env.NEXT_PUBLIC_CLUB_URL}/restaurants/festival/${festivalSlug}`
      default:
      return `${process.env.NEXT_PUBLIC_CLUB_URL}/festivals`
  }
}

export const hasWindow = () => typeof window !== 'undefined'
