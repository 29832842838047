'use client'

import { ApolloLink } from '@apollo/client'
import { ApolloNextAppProvider, NextSSRApolloClient, NextSSRInMemoryCache } from '@apollo/experimental-nextjs-app-support/ssr'

import { getApolloLinks } from '~/lib/apollo/links'

// have a function to create a client for you
function makeClient(apiURL?: string) {
  return new NextSSRApolloClient({
    // use the `NextSSRInMemoryCache`, not the normal `InMemoryCache`
    cache: new NextSSRInMemoryCache(),
    link: ApolloLink.from(getApolloLinks({ apiURL })),
  })
}

export function ApolloWrapper({ children, apiURL }: React.PropsWithChildren<{ apiURL?: string }>) {
  return <ApolloNextAppProvider makeClient={() => makeClient(apiURL)}>{children}</ApolloNextAppProvider>
}
