import { ReadonlyHeaders } from 'next/dist/server/web/spec-extension/adapters/headers'
import { ReadonlyRequestCookies } from 'next/dist/server/web/spec-extension/adapters/request-cookies'

import { HttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { API } from '~/utils/api'
import { HEADERS } from '~/utils/headers'

export const getApolloLinks = ({
  req,
  apiURL = API.PL,
}: {
  req?: { cookies: ReadonlyRequestCookies; headers: ReadonlyHeaders }
  apiURL?: string
}) => {
  const langLink = setContext((_, { headers = {} }) => {
    return {
      headers: {
        ...headers,
        [HEADERS.X_LANGUAGE]: req?.headers.get(HEADERS.X_LANGUAGE),
      },
    }
  })
  return [
    langLink,
    new HttpLink({
      uri: apiURL + '/graphql', // Server URL (must be absolute)
      credentials: 'include',
      headers: {
        ...(req && { cookie: req.cookies.toString() }),
      },
    }),
  ]
}
